<template>
  <div class="authenticaResult">
    <div class="result-img">
      <img src="@/assets/images/login/auth-success.svg" v-if="result === 1" />
      <img src="@/assets/images/login/auth-failed.svg" v-else />
    </div>
    <div class="result-text" :class="{ fail: result === 0 }">{{ result === 1 ? '认证成功' : '认证失败' }}</div>
    <div class="time-text">{{ seconds }}s后返回首页</div>
    <div class="return-btn" @click="goBack">返回首页</div>
  </div>
</template>
<script lang="ts">
export default {
  data() {
    return {
      seconds: 3,
      result: 1, //1.代表成功  0.代表失败
      timer: null,
    }
  },

  computed: {
    redirectUrl() {
      return this.$store.state.redirectUrl
    },
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  mounted() {
    if (this.$route.query.result) {
      this.result = parseInt(this.$route.query.result)
    }
    this.timer = setInterval(() => {
      if (this.seconds <= 0) {
        this.seconds = 0
        clearInterval(this.timer)
        this.goBack()
      } else {
        this.seconds--
      }
    }, 1000)
  },
  methods: {
    goBack() {
      if (this.result === 1) {
        if (this.redirectUrl) {
          let tempUrl = this.redirectUrl
          this.$store.commit('setRedirectUrl', '')
          this.$router.replace(tempUrl)
        } else {
          this.$router.replace('/first/talentList')
        }
      } else {
        this.$router.replace('/login')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.authenticaResult {
  padding-top: 20vh;
  width: 100%;
  height: 100vh;
  .result-img {
    width: 20vw;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }

  .result-text {
    text-align: center;
    margin-top: 0.2rem;
    font-size: 0.35rem;
    color: #1890ff;
  }

  .fail {
    color: #f07070;
  }

  .time-text {
    text-align: center;
    margin-top: 1rem;
    font-size: 0.35rem;
    color: #cccccc;
  }
  .return-btn {
    text-align: center;
    font-size: 0.37rem;
    background-color: #1891ff;
    color: #ffffff;
    width: 50%;
    padding: 0.2rem 0;
    margin: 2rem auto 0 auto;
  }
}
</style>
